import Vue from 'vue';
import Meta from 'vue-meta';
import 'bootstrap';
// import VueGtm from 'vue-gtm';
import './assets/styles/style.scss';
import Particles from 'particles.vue';
// import VueAnalytics from 'vue-analytics';
import animate from 'animate.css';
import VueObserveVisibility from 'vue-observe-visibility';
import App from './App.vue';
import store from './store';
import router from './router';

// const cookieSetting = (localStorage.getItem('cookie:accepted') === 'true');

// Vue.use(VueAnalytics, {
//   id: 'UA-188070968-2',
//   disabled: !cookieSetting,
//   debug: {
//     enabled: false,
//   },
// });

// Vue.use(VueGtm, {
//   id: 'GTM-5F6WRD3',
//   defer: false,
//   compatibility: false,
//   enabled: false,
//   debug: true,
//   loadScript: true,
//   trackOnNextTick: false,
// });

Vue.use(animate);
Vue.use(VueObserveVisibility);

Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
  title: 'Chris Fassl Music',
  meta: [
    {
      vmid: 'description',
      name: 'description',
      content: 'Discover Chris Fassl Music, an indie artist blending folk instruments like bagpipes and nyckelharpa with epic orchestral sounds. Explore enchanting melodies and cinematic soundscapes that transport you to ancient realms and futuristic landscapes.',
    },
  ],
});
Vue.use(Particles);

window.addEventListener('resize', () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

Vue.config.productionTip = false;
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
